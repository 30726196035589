



























































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { CampaignItem } from '@/models/CampaignItem';
import { OpCard } from '@/models/opcards/OpCard';

@Component
export default class CampaignButtonPanel extends Vue {
  @Prop({ required: true })
  readonly disabled!: boolean;

  @Prop({ required: true })
  campaign!: CampaignItem;

  @Prop({ required: true })
  readonly isEntry!: boolean;

  @Prop({ required: true })
  readonly isPermittedEntry!: boolean;

  @Prop({ required: true })
  readonly mainOpCard!: OpCard;
}
