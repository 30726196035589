











































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { CampaignOption } from '@/models/CampaignItem';

@Component
export default class CampaignOptionPanel extends Vue {
  @Prop({ required: true })
  readonly isMultipleSelect!: boolean;

  @Prop({ required: true })
  readonly disabled!: boolean;

  optionErrMsg = '';

  // 要素オブジェクトの選択フラグを書き換えるためにsyncを付与。要素の順番は変更しない
  @PropSync('options', { required: true })
  syncOptions!: CampaignOption[];

  // 未選択状態をデフォルトに設定するため、ありえない数値を設定
  selectedSingleOptionIndex: number = -1;

  setIsSelected() {
    this.syncOptions.forEach(
      (o, i) => (o.isSelected = i === this.selectedSingleOptionIndex)
    );
    this.validateOption();
  }

  validateOption() {
    if (this.syncOptions.filter(o => o.isSelected).length) {
      this.optionErrMsg = '';
      return true;
    } else {
      this.optionErrMsg = this.$msg.get('2000209');
      return false;
    }
  }
}
