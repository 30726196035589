import Vue from 'vue';
import * as api from '@/gen/api';
import { ICampaignEntryRepository } from '@/repositories/interface/ICampaignEntryRepository';

export class CampaignEntryRepository implements ICampaignEntryRepository {
  public constructor() {}

  private async createCampaignApiClient() {
    const config = await Vue.prototype.$getConfigWithToken(Vue.prototype.$auth);
    return new api.CampaignApi(config);
  }

  /*
   * キャンペーン応募
   */
  public async postEntryCampaign(
    campaignId: string,
    postEntryCampaignReqBody: api.ReqPostCampaignEntry
  ) {
    const client = await this.createCampaignApiClient();
    await client.postCampaignEntry(campaignId, postEntryCampaignReqBody);
  }

  /*
   * キャンペーン応募確認
   */
  public async getCampaignConfirm(campaignId: string) {
    const client = await this.createCampaignApiClient();
    const res = await client
      .getCampaignConfirm(campaignId)
      .catch(Vue.prototype.$tokenErrHandler);
    return res;
  }

  /*
   * キャンペーン応募取消
   */
  public async deleteCampaignEntry(campaignId: string, campaignTitle: string) {
    const client = await this.createCampaignApiClient();
    const res = await client.deleteCampaignCancel(campaignId, campaignTitle);
    return res;
  }
}
