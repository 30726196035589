
























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ErrorDialog extends Vue {
  @Prop({ required: true })
  readonly value!: boolean;

  @Prop({ required: true })
  readonly errorMsg!: string;

  get wrapperDialog() {
    return this.value;
  }

  set wrapperDialog(flag: boolean) {
    this.$emit('input', flag);
  }
}
