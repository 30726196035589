






























































































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import { CampaignRepository } from '@/repositories/CampaignRepository';
import { CampaignEntryRepository } from '@/repositories/CampaignEntryRepository';
import { CampaignItem } from '@/models/CampaignItem';
import ContactPanel from '@/components/ContactPanel.vue';
import OnceButton from '@/components/OnceButton.vue';
import ErrorDialog from '@/components/ErrorDialog.vue';
import CampaignDetailHeader from '@/components/Campaign/CampaignDetailHeader.vue';
import CampaignOptionPanel from '@/components/Campaign/CampaignOptionPanel.vue';
import FreeForm from '@/components/FreeForm.vue';
import CampaignButtonPanel from '@/components/Campaign/CampaignButtonPanel.vue';
import CampaignCancelConfirmDialog from '@/components/Campaign/CampaignCancelConfirmDialog.vue';
import CampaignEntryCompleteDialog from '@/components/Campaign/CampaignEntryCompleteDialog.vue';
import { OpCardList, OpCard } from '@/models/opcards/OpCard';
import { mapState } from 'vuex';
import { ReqPostCampaignEntry } from '@/gen/api';
import * as cmsUtils from '@/common/cmsUtils';

@Component({
  components: {
    ContactPanel,
    'once-button': OnceButton,
    CampaignDetailHeader,
    CampaignOptionPanel,
    FreeForm,
    CampaignButtonPanel,
    CampaignCancelConfirmDialog,
    CampaignEntryCompleteDialog,
    ErrorDialog
  },
  computed: { ...mapState(['microCMS']) }
})
export default class P0147 extends Vue {
  @Ref() readonly CampaignOptionPanel!: CampaignOptionPanel;
  @Ref() readonly FreeForm!: FreeForm;
  // 自由入力欄の最大文字数設定
  readonly FREE_FORM_MAX = 1000;

  readonly campaignIdKey = 'campaignId';
  readonly campaignNameKey = 'campaignName';
  campaign = {} as CampaignItem;
  isEntry = false;
  headerText = '';
  freeFormText = '';
  pageLoaded = false;
  buttonLoading = false;
  isCancelConfirmDialogOpening = false;
  isEntryCompleteDialogOpening = false;
  isErrorDialogOpening = false;
  errorMsg = '';

  get campaignRepository() {
    return new CampaignRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  get campaignEntryRepository() {
    return new CampaignEntryRepository();
  }

  get enabledPostButton() {
    if (!this.campaign) {
      return false;
    }
    // microCMS側の設定を最優先
    if (this.campaign.disableEntryBtn) {
      return false;
    }
    // 未ログイン時はログインボタンを表示するため、ボタンは活性
    if (!this.$auth.isAuthenticated) {
      return true;
    }
    // エントリー時は券種関係なくエントリー解除できるため、ボタンは活性
    if (this.isEntry) {
      return true;
    }

    return this.isPermittedEntry;
  }

  get enabledCampaignForm() {
    return (
      this.campaign &&
      this.$auth.isAuthenticated &&
      this.isPermittedEntry &&
      !this.isEntry
    );
  }

  get showCampaignOptionPanel() {
    return this.campaign
      ? this.campaign.isShowOptionsForm &&
          this.campaign.isAvailableEntryTime &&
          this.$auth.isAuthenticated
      : false;
  }

  get showFreeForm() {
    return this.campaign
      ? this.campaign.showFreeForm &&
          this.campaign.isAvailableEntryTime &&
          this.$auth.isAuthenticated
      : false;
  }

  get isMultipleSelect() {
    return this.campaign ? this.campaign.isMultipleSelect : false;
  }

  get campaignId() {
    const queryCampaignId = this.$route.query.id as string;
    if (queryCampaignId) {
      return queryCampaignId;
    }
    const storageCampaignId = localStorage.getItem(this.campaignIdKey);
    localStorage.removeItem(this.campaignIdKey);
    return storageCampaignId;
  }

  get mainOpCard() {
    if (!this.$auth.isAuthenticated) {
      return {} as OpCard;
    }
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    ).selectMainCard;
  }

  @Watch('$auth.loading', { immediate: true })
  async initialize() {
    if (this.$auth.loading) {
      return;
    }

    if (!this.campaignId) {
      this.$router.replace('/notfound');
      return;
    }

    const item = await this.campaignRepository.select(this.campaignId);
    if (!item) {
      return this.$router.replace('/notfound');
    }
    this.campaign = item;

    if (this.$auth.isAuthenticated) {
      const res = await this.campaignEntryRepository.getCampaignConfirm(
        this.campaignId
      );
      if (res.data.is_entried) {
        this.isEntry = true;
        this.headerText = this.$msg.get('2000020');
      }
    }
    this.pageLoaded = true;
  }

  handleClick() {
    if (!this.campaignId) {
      return;
    }
    this.isEntry ? this.openCancelConfirmDialog() : this.entryCampaign();
  }

  async entryCampaign() {
    // エントリーボタン押下時にログインしていない場合、ログイン画面へ遷移させる
    if (!this.$auth.isAuthenticated) {
      const queryCampaignId = this.$route.query.id as string;
      localStorage.setItem(this.campaignIdKey, queryCampaignId);
      localStorage.setItem(this.campaignNameKey, this.campaign.title);
      this.$auth.loginWithRedirect({
        redirect_uri: `${process.env.VUE_APP_SERVICE_URL}/campaign`
      });
    } else {
      if (await this.validateForm()) {
        const body: ReqPostCampaignEntry = {
          campaign_title: this.campaign.title,
          campaign_options: this.campaign.campaignOptions
            .filter(o => o.isSelected)
            .map(o => o.text),
          additional_user_input: this.freeFormText
        };
        this.campaignEntryRepository
          .postEntryCampaign(this.campaignId!, body)
          .then(() => {
            this.isEntryCompleteDialogOpening = true;
            this.isEntry = true;
            this.headerText = this.$msg.get('2000020');
          })
          .catch(() => {
            this.errorMsg = this.$msg.get('2000011');
            this.isErrorDialogOpening = true;
          });
      }
    }
  }

  async validateForm() {
    const result = Promise.all([
      this.campaign.isShowOptionsForm
        ? this.CampaignOptionPanel.validateOption()
        : true,
      this.campaign.showFreeForm ? this.FreeForm.$validator.validateAll() : true
    ]);
    return (await result).every(val => val);
  }

  openCancelConfirmDialog() {
    this.isCancelConfirmDialogOpening = true;
  }

  deleteCampaignEntry() {
    // ボタン連打防止のための buttonLoading を確認する
    if (this.buttonLoading) {
      return;
    }
    this.buttonLoading = true;
    this.campaignEntryRepository
      .deleteCampaignEntry(this.campaignId!, this.campaign.title)
      .then(() => {
        this.isEntry = false;
        this.headerText = '';
      })
      .catch(err => {
        if (err.response.status === 404) {
          this.headerText = this.$msg.get('2000019');
        } else {
          this.errorMsg = this.$msg.get('2000011');
          this.isErrorDialogOpening = true;
        }
      })
      .finally(() => {
        this.buttonLoading = false;
        this.isCancelConfirmDialogOpening = false;
      });
  }

  // 応募許可券種かどうかを判定
  get isPermittedEntry() {
    if (!this.$auth.isAuthenticated) {
      return false;
    }
    if (this.campaign.isPermitTargetAll) {
      return true;
    }
    if (this.campaign.isPermitTargetGroup) {
      return this.$store.state.microCMS.isMicroCMSFetched
        ? cmsUtils.isIncludedGroupCardTypes(
            this.mainOpCard.odakyuCustomerNo,
            this.campaign.permitCardTypeGroups,
            this.cardType
          )
        : false;
    }
    if (this.campaign.isPermitTargetSingle) {
      return cmsUtils.isIncludedCardTypes(
        this.mainOpCard.odakyuCustomerNo,
        this.campaign.permitCardTypes
      );
    }
    return false;
  }

  get cardType() {
    return this.$store.state.microCMS.cardType;
  }

  get permitCardTypeText() {
    if (this.campaign.isPermitTargetAll) {
      return '全券種';
    }
    if (this.campaign.permitCardTypeText) {
      return this.campaign.permitCardTypeText;
    }
    if (this.campaign.isPermitTargetGroup) {
      return this.campaign.permitCardTypeGroups.map(g => g.name).join('、');
    }
    if (this.campaign.isPermitTargetSingle) {
      return this.campaign.permitCardTypes.map(c => c.name).join('、');
    }
    return '';
  }
}
