
















import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';

@Component
export default class FreeForm extends Vue {
  @PropSync('freeFormText', { required: true })
  syncFreeFormText!: string;

  @Prop({ required: true })
  readonly disabled!: boolean;

  @Prop({ required: true })
  readonly required!: boolean;

  @Prop({ required: true })
  readonly title!: string;

  @Prop({ required: true })
  readonly dataAsText!: string;

  @Prop({ required: true })
  readonly max!: number;
}
