


















import { Component, Vue, Prop } from 'vue-property-decorator';
import { CampaignItem } from '@/models/CampaignItem';

@Component
export default class CampaignDetailHeader extends Vue {
  @Prop({ required: true })
  readonly headerText!: string;

  @Prop({ required: true })
  readonly campaign!: CampaignItem;

  @Prop({ required: true })
  readonly permitCardTypeText!: string;

  get isNewCampaign() {
    return this.campaign ? this.campaign.isNewCampaign : false;
  }
}
